import React, { useState } from 'react';
import { Card, Button, Badge, Collapse, ListGroup, Row, Col, Modal, Form } from 'react-bootstrap';
import { useGetSellerOrdersQuery, useUpdateOrderMutation, useUpdateRejectedOrderBySellerMutation } from '../../../Redux/API/ApiSlice';
import { getUserDetails } from '../../../Helper/SessionHelper';
import { countryType, Symbol } from '../../../Helper/constant';
import moment from "moment";
import FormModal from '../../Common/Modal/FormModal';
import axios from 'axios';
import { BaseURL } from '../../../Helper/config';

function PendingSellerOrder() {
  const [open, setOpen] = useState({}); 
  const [showModal, setShowModal] = useState(false); 
  const [selectedOrderId, setSelectedOrderId] = useState(null); 
  const [rejectionReason, setRejectionReason] = useState(""); 
  const [reasonOptions] = useState(["Busy", "Stock Out", "Don't Want", "Others"]); 
  const [customReason, setCustomReason] = useState("");

  const [updateOrder, { isError, isSuccess }] = useUpdateOrderMutation();
  const [updateRejectedOrder]=useUpdateRejectedOrderBySellerMutation()

  const toggleDetails = (orderId) => {
    setOpen((prev) => ({ ...prev, [orderId]: !prev[orderId] }));
  };

  const handleRejectClick = (data) => {
    const id = data._id;
    setSelectedOrderId(id);
    setShowModal(true); 
  };

  const handleModalClose = () => {
    setShowModal(false); // Close the modal
    setRejectionReason(""); // Reset the rejection reason
    setSelectedOrderId(null); // Reset the selected order ID
    setCustomReason(""); // Reset the custom reason
  };

  const handleRejectOrder = async() => {
    // Add the logic to reject the order here
    console.log(`Order ${selectedOrderId} rejected for reason: ${rejectionReason}`);
    // updateOrder({
    //     id: selectedOrderId,
    //     data: {
    //         rejectionReason: rejectionReason === "Others" ? customReason : rejectionReason,
    //         orderStatusId: "64cf5d349aae4565fb21d9e1",
    //         orderStatus: "Rejected By Seller",
    //     },
    //   });

      updateRejectedOrder({
        id: selectedOrderId,
        data: {
            rejectionReason: rejectionReason === "Others" ? customReason : rejectionReason,
            orderStatusId: "64cf5d349aae4565fb21d9e1",
            orderStatus: "Rejected By Seller",
        },
      })
    // await axios.post(`${BaseURL}/create-coupon`,{

    // })
    handleModalClose();
  };

  const handleAccept = (data) => {
    console.log(data);
    const id = data._id;
    updateOrder({
      id: id,
      data: {
        orderStatusId: "64cf5d349aae4565fb21d9e1",
        orderStatus: "Accepted By Seller",
      },
    });
  };

  const { data, isLoading, error } = useGetSellerOrdersQuery({
    id: getUserDetails()?.sellerIDInfo[0]?._id,
    orderStatus: undefined,
    page: 1,
    limit: 1000,
  });

  const SkeletonLoader = () => (
    <div style={{ padding: '20px', borderRadius: '16px', background: 'linear-gradient(135deg, #f5f5f5, #e0e0e0)', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
      <div style={{ width: '100%', height: '24px', borderRadius: '5px', backgroundColor: '#ddd', marginBottom: '10px' }}></div>
      <div style={{ width: '60%', height: '24px', borderRadius: '5px', backgroundColor: '#ddd', marginBottom: '20px' }}></div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ width: '30%', height: '16px', borderRadius: '5px', backgroundColor: '#ddd' }}></div>
        <div style={{ width: '20%', height: '16px', borderRadius: '5px', backgroundColor: '#ddd' }}></div>
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}>
        <div style={{ width: '100px', height: '36px', borderRadius: '20px', backgroundColor: '#ddd', marginRight: '10px' }}></div>
        <div style={{ width: '100px', height: '36px', borderRadius: '20px', backgroundColor: '#ddd' }}></div>
      </div>
    </div>
  );

  return (
    <>
      {isLoading ? (
        <SkeletonLoader />
      ) : (
        <>
          {data?.data?.orders[0]?.orderinfo?.map((item) => (
            <Card
              className="mb-4 order-card"
              style={{
                border: 'none',
                borderRadius: '16px',
                transition: 'transform 0.3s ease-in-out ',
              }}
              onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.02)'}
              onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              <Card.Header className="order-card-header" style={{ background: "#1E466A", color: "white" }}>
                <h5 className='text-white'>Order #{item?.orderNumber}</h5>
                <Badge className="status-badge">Pending</Badge>
              </Card.Header>

              <Card.Body className="order-card-body">
                <Row className="align-items-center">
                  <Col md={6} className="d-flex justify-content-between align-items-center">
                    <h6>Total: <span className="total-amount">{countryType === "bdt" ? Symbol.bdt : Symbol.usd}{item?.orderTotalAmount}</span></h6>
                    <Button
                      variant="link"
                      onClick={() => toggleDetails(item?.orderNumber)}
                      aria-expanded={open[item?.orderNumber]}
                      className="toggle-details-btn"
                    >
                      {open[item?.orderNumber] ? 'Hide Details' : 'Show Details'}
                    </Button>
                  </Col>
                  <Col md={6} className="text-end">
                    <h6>Status: <span>{item?.orderStatus}</span></h6>
                  </Col>
                </Row>

                <Collapse in={open[item?.orderNumber]}>
                  <div className="order-details">
                    <Row className="mt-4">
                      <Col md={6}>
                        <Card.Title>Customer Information</Card.Title>
                        <Card.Text>
                          <strong>Name:</strong> {item?.orderDeliveryAddress?.Name}<br />
                          <strong>Phone:</strong> {item?.orderDeliveryAddress?.phoneNumber}<br />
                          <strong>Address:</strong> {item?.orderDeliveryAddress?.addressText?.length > 20
                            ? `${item?.orderDeliveryAddress?.addressText?.slice(0, 15)}...`
                            : item?.orderDeliveryAddress?.addressText}
                        </Card.Text>
                      </Col>

                      <Col md={6}>
                        <Card.Title>Payment Method</Card.Title>
                        <Card.Text>{item?.paymentMethod || "N/A"}</Card.Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Card.Title>Order Type</Card.Title>
                        <Card.Text>{item?.OrderLabel}</Card.Text>
                      </Col>

                      <Col md={6}>
                        <Card.Title>Delivery Date</Card.Title>
                        <Card.Text>{moment(item?.deliveryDateTime).format("MMMM Do YYYY, h:mm:ss a")}</Card.Text>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Card.Title>Payment Date</Card.Title>
                        <Card.Text>{moment(item?.paymentDate).format("MMMM Do YYYY, h:mm:ss a")}</Card.Text>
                      </Col>

                      <Col md={6}>
                        <Card.Title>Status</Card.Title>
                        <Card.Text>{item?.orderStatus}</Card.Text>
                      </Col>
                    </Row>
                  </div>
                </Collapse>
              </Card.Body>

              <Card.Footer className="order-card-footer">
                <div className="d-flex justify-content-end">
                  <Button variant="danger" onClick={() => handleRejectClick(item)} >Reject Order</Button>
                  <Button variant="success" onClick={() => handleAccept(item)} className="ms-2">Accept Order</Button>
                </div>
              </Card.Footer>
            </Card>
          ))}
        </>
      )}
     <FormModal 
  show={showModal} 
  handleClose={handleModalClose} 
  title="Reject Order"
  actionButtonTitle="Reject Order" 
  handleActionButton={handleRejectOrder} 
  actionButtonDisabled={!rejectionReason} 
>

  <Form.Group>
    <Form.Label>Select Reason</Form.Label>
    <Form.Control as="select" value={rejectionReason} onChange={(e) => {
      setRejectionReason(e.target.value);
      if (e.target.value !== "Others") setCustomReason("");
    }}>
      <option value="">Select a reason</option>
      {reasonOptions.map((option, index) => (
        <option key={index} value={option}>{option}</option>
      ))}
      <option value="Others">Others</option>
    </Form.Control>
  </Form.Group>
  {rejectionReason === "Others" && (
    <Form.Group className="mt-2">
      <Form.Label>Specify Reason</Form.Label>
      <Form.Control type="text" value={customReason} onChange={(e) => setCustomReason(e.target.value)} />
    </Form.Group>
  )}
</FormModal>
    </>
  );
}

export default PendingSellerOrder;
